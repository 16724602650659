const TokenKey = 'Admin-Token';
const PushKey = '_GNS_ACTIVE_PUSH';
const AnsweredPushKey = '_GNS_ANSWERED_PUSH';

export function getToken() {
  if (localStorage.getItem(TokenKey)) {
    try {
      return localStorage.getItem(TokenKey)
    } catch(e) {
      localStorage.removeItem(TokenKey)
    }
  }
  return null
}

export function setToken(token) {
  localStorage.setItem(TokenKey, token)
}

export function removeToken() {
  if (localStorage.getItem(TokenKey)) {
    localStorage.removeItem(TokenKey)
  }
}

export function isAuthenticated() {
  return getToken()
}


export function getPushNotification(sufix) {
  let key = (sufix != "" ? PushKey + '_' + sufix : PushKey)
  if (localStorage.getItem(key)) {
    try {
      return localStorage.getItem(key) === true || localStorage.getItem(key) === 'true'
    } catch(e) {
      localStorage.removeItem(key)
    }
  }
  return false
}

export function setPushNotification(val, sufix) {
  let key = (sufix != "" ? PushKey + '_' + sufix : PushKey)
  localStorage.setItem(key, val)
}

export function getAnsweredPushNotification() {
  if (localStorage.getItem(AnsweredPushKey)) {
    try {
      return localStorage.getItem(AnsweredPushKey)
    } catch(e) {
      localStorage.removeItem(AnsweredPushKey)
    }
  }
  return false
}

export function setAnsweredPushNotification(val) {
  localStorage.setItem(AnsweredPushKey, val)
}

export function hasVerifiedData(user) {
  if('mobile_verified' in user) {
    return user.mobile_verified || user.email_verified
  }
  return false
}