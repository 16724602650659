import { getCollections } from '@/api/core';

const state = {
  collections: {
    tipos_legajo: [],
    tipos_adelanto: [],
    tipos_licencia: [],
    contratos: [],
    tipos_liquidacion: [],
    estado_civil: [
      {
        id: 1,
        value: 'Soltero/a',
      },
      {
        id: 2,
        value: 'Casado/a',
      },
      {
        id: 3,
        value: 'Viudo/a',
      },
      {
        id: 4,
        value: 'Concubino/a',
      },
      {
        id: 5,
        value: 'Divorciado/a',
      }
    ],

    departamentos: [
      {
        id: 1,
        value: 'Artigas',
      },
      {
        id: 2,
        value: 'Canelones',
      },
      {
        id: 3,
        value: 'Cerro Largo',
      },
      {
        id: 4,
        value: 'Colonia',
      },
      {
        id: 5,
        value: 'Durazno',
      },
      {
        id: 1,
        value: 'Flores',
      },
      {
        id: 2,
        value: 'Florida',
      },
      {
        id: 3,
        value: 'Lavalleja',
      },
      {
        id: 4,
        value: 'Maldonado',
      },
      {
        id: 5,
        value: 'Montevideo',
      },
      {
        id: 1,
        value: 'Paysandu',
      },
      {
        id: 2,
        value: 'Rio Negro',
      },
      {
        id: 3,
        value: 'Rivera',
      },
      {
        id: 4,
        value: 'Rocha',
      },
      {
        id: 5,
        value: 'Salto',
      },
      {
        id: 2,
        value: 'San Jose',
      },
      {
        id: 3,
        value: 'Soriano',
      },
      {
        id: 4,
        value: 'Tacuarembo',
      },
      {
        id: 5,
        value: 'Treinta y Tres',
      }
    ],
    nacionalidades: [
      {
        id: 1,
        value: 'URUGUAYO NATURAL',
      },
      {
        id: 2,
        value: 'CIUDADANO LEGAL',
      },
      {
        id: 3,
        value: 'EXTRANJERO',
      }
    ],
    areas:[],
    browsers:[]
  }
};

const mutations = {
  SET_COLLECTIONS: (state, data) => {
    state.collections.tipos_legajo = Object.prototype.hasOwnProperty.call(data, "lstTMLegajo") ? Object.values(data.lstTMLegajo) : []
    state.collections.tipos_adelanto = Object.prototype.hasOwnProperty.call(data, "lstTAdel") ? Object.values(data.lstTAdel) : []
    state.collections.tipos_licencia = Object.prototype.hasOwnProperty.call(data, "lstTPLic") ? Object.values(data.lstTPLic) : []
    state.collections.contratos = Object.prototype.hasOwnProperty.call(data, "lstContratosAct") ? Object.values(data.lstContratosAct) : []
    state.collections.tipos_liquidacion = Object.prototype.hasOwnProperty.call(data, "lstTiposLiq") ? Object.values(data.lstTiposLiq) : []
  },

  SET_AREAS: (state, data) => {
    state.collections.areas = data
  },

  SET_BROWSERS: (state, data) => {
    state.collections.browsers = data
  },
};

const actions = {
  initCollections({ commit }) {
    return new Promise((resolve, reject) => {
      getCollections()
        .then(response => {
            commit('SET_COLLECTIONS', response);
            resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },

};

export default {
  name: 'dict',
  namespaced: true,
  state,
  mutations,
  actions,
};

