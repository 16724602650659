import request from '@/utils/request';
import axios from 'axios';

export function login(host, data) {
  return axios.post(host + '/auth/login', data)
}

export function refresh(data) {
  return axios.post('/auth/refresh', data)
}

export function getInfo() {
  return request({
    url: '/user',
    method: 'get',
  });
}

export function logout() {
  return request({
    url: '/logout',
    method: 'post',
  });
}

export function requestResetPassword(params) {
  return request({
    url: '/auth/request-reset',
    method: 'post',
    data: params
  });
}

export function requestSendCode(params) {
  return request({
    url: '/auth/send-code',
    method: 'post',
    data: params
  });
}

export function requestResendCode(params) {
  return request({
    url: '/auth/resend-code',
    method: 'post',
    data: params
  });
}

export function requestChangePassword(params) {
  return request({
    url: '/auth/change-password',
    method: 'post',
    data: params
  });
}

export function resetPassword(params) {
  return request({
    url: '/auth/reset',
    method: 'post',
    data: params
  });
}
