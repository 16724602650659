<template>
    <div class="text-center">
        <v-bottom-sheet v-model="show">
            <v-sheet class="text-center pa-5">
                <div class="pb-3">
                    ¿ Deseas recibir notificaciones ?
                </div>
                <v-btn 
                    class="mr-5"
                    color="grey"
                    dark
                    @click.prevent="cancelPush"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="primary"
                    dark
                    @click.prevent="activePush"
                >
                    Activar
                </v-btn>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>


<script>
export default {
    name: "pushPrompt",
    props: {
        show: {
            type: [Boolean],
            default: false
        }
    },
    
    data() {
        return {

        }
    },
    
    created() {

    },

    methods: {
        cancelPush() {
            this.$emit('cancelAction', false)
        },

        activePush() {
            this.$emit('activeAction')
        }
    }
}
</script>
