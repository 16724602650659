require('es6-promise').polyfill()

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from '@/router'
import '@/permission'; // permission control
import '@/assets/normalize-min.css'
import '@/assets/styles.css'
import './registerServiceWorker'
import "vue-phone-number-input/dist/vue-phone-number-input.css";

Vue.config.productionTip = false

Vue.config.warnHandler = function(msg, vm, trace) {
  console.log(`Warn: ${msg}\nTrace: ${trace}`);
}

Vue.config.errorHandler = function(err, vm, info) {
  console.log(`Error: ${err.toString()}\nInfo: ${info}`);
}

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
