import axios from 'axios';
import router from '@/router';
import store from '@/store';
import { getToken, setToken } from '@/utils/auth';

// Create axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_MIX_BASE_API,
  timeout: 30000, // Request timeout
})

// Request intercepter
service.interceptors.request.use(
  config => {
    const token = getToken();
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token; // Set JWT token
    }

    //config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    return config;
  },
  error => {
    // Do something with request error
    console.log(error); // for debug
    Promise.reject(error);
  }
);

// TODO: https://blog.liplex.de/axios-interceptor-to-refresh-jwt-token-after-expiration/
// response pre-processing
service.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    const config = error.config;

    if(error.response && error.response.data && error.response.data.refresh_token && !config._retry) {
      // 1) put token to LocalStorage
      setToken(error.response.data.refresh_token)

      // 2) Change Authorization header
      config.headers['Authorization'] = `Bearer ${error.response.data.refresh_token}`;

      // 3) return originalRequest object with Axios.
      return new Promise((resolve, reject) => {
        axios.request(config).then(response => {
          resolve(response.data);
        }).catch((error) => {
          reject(error);
        })
      })
    }

    let error_data = error.response.data
    let message = null
    let code = error.response.status

    if(Object.prototype.hasOwnProperty.call(error_data, 'code') && Object.prototype.hasOwnProperty.call(error_data, 'message')) {
      message = error_data.message + " | Código: " + error_data.code
      code = error_data.code
    } else if (error_data.errors) {
      let errors_messages = error_data.errors
      message = '<ul>'
      for(let m in errors_messages) {
        message+= '<li>' + errors_messages[m].join(' - ') + '</li>'
      }
      message+= '</ul>'
    } else if (error_data.error) {
      message = error_data.error
    } else {
      message = 'Ha ocurrido un error en el servidor. Intenta conectarte nuevamente.'
    }

    store.commit('stopProcessing')

    if([1000,1001,1002,401].includes(code)) {
      store.dispatch('user/logout');
      router.push('/login');
    } else {
      store.commit('setSnackBar', {
        'message': message,
        'show': true,
        'color': '#cc0605',
        'timeout': 10000
      })
    }

    return Promise.reject(error);
  },
);

export default service;
