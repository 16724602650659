<template>
  <div>
    <!-- HOME HEADER + MENU -->
    <!-- Sizes your content based upon application components -->
    <v-main>
      <v-container v-show="navigationDrawer">
        <!-- Home Right Menu Drawer -->
        <v-navigation-drawer
          id="menu-drawer"
          v-model="drawer"
          fixed
          temporary
          right
        >
          <v-list-item>
            <router-link :to="{ name: 'perfil' }">
              <div class="d-flex flex-row align-start py-4">
                <v-avatar v-if="user.avatar" size="40">
                  <img :src="user.avatar" alt="Imagen del usuario" />
                </v-avatar>
                <v-avatar v-else size="40">
                  <img src="@/assets/home/user.jpeg" alt="Avatar del usuario" />
                </v-avatar>
                <div class="d-flex flex-column ml-4">
                  <span class="nombre-usuario d-inline-block text-truncate">{{
                    user.name
                  }}</span>
                  <span class="nombre-empresa d-inline-block text-truncate">{{
                    user.company
                  }}</span>
                </div>
              </div>
            </router-link>
          </v-list-item>
          <v-divider></v-divider>
          <v-list>
            <router-link :to="{ name: 'home' }">
              <v-list-item link @click.stop="drawer = !drawer">
                <v-list-item-action>
                  <svg
                    class="bottom-home"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M8.48 0.820027L0.957 5.41503C0.362397 5.77844 -0.000173186 6.42516 6.20603e-08 7.12203V16C6.20603e-08 17.1046 0.895431 18 2 18H16C17.1046 18 18 17.1046 18 16V7.12203C18 6.42571 17.6379 5.7795 17.044 5.41603L9.522 0.820027C9.20211 0.62477 8.79989 0.62477 8.48 0.820027ZM6 11C6 10.4477 6.44772 10 7 10H11C11.5523 10 12 10.4477 12 11V14C12 14.5523 11.5523 15 11 15H7C6.44772 15 6 14.5523 6 14V11Z"
                      fill="#DEDEDE"
                    />
                  </svg>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Home</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <router-link :to="{ name: 'liquidaciones' }">
              <v-list-item link>
                <v-list-item-action>
                  <img src="@/assets/home/menu/recibos.svg" />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Recibos</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <router-link :to="{ name: 'solicitar' }">
              <v-list-item link>
                <v-list-item-action>
                  <img src="@/assets/home/menu/solicitar.svg" />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Solicitar</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <router-link :to="{ name: 'noticias' }">
              <v-list-item link>
                <v-list-item-action>
                  <svg
                    width="19"
                    height="18"
                    viewBox="0 0 19 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.5117 1.64807C18.2409 1.64807 18.0194 1.85796 18.0194 2.11449V14.4941C18.0194 15.8039 16.8952 16.8651 15.5167 16.8651H3.48736C2.10472 16.8651 0.984666 15.8001 0.984666 14.4941V1.1389H15.3977V13.1492C15.3977 13.4058 15.6193 13.6157 15.89 13.6157C16.1608 13.6157 16.3824 13.4058 16.3824 13.1492V0.672476C16.3824 0.415944 16.1608 0.206055 15.89 0.206055H0.492333C0.22155 0.206055 0 0.415944 0 0.672476V14.4902C0 16.3131 1.56316 17.794 3.48736 17.794H15.5126C17.4368 17.794 19 16.3131 19 14.4902V2.11449C19.0041 1.85796 18.7825 1.64807 18.5117 1.64807Z"
                      fill="#757575"
                    />
                    <path
                      d="M13.5104 3.82859C13.7811 3.82859 14.0027 3.6187 14.0027 3.36217C14.0027 3.10564 13.7811 2.89575 13.5104 2.89575H2.77749C2.50671 2.89575 2.28516 3.10564 2.28516 3.36217C2.28516 3.6187 2.50671 3.82859 2.77749 3.82859H13.5104Z"
                      fill="#757575"
                    />
                    <path
                      d="M13.5104 12.403H2.77749C2.50671 12.403 2.28516 12.6128 2.28516 12.8694C2.28516 13.1259 2.50671 13.3358 2.77749 13.3358H13.5063C13.777 13.3358 13.9986 13.1259 13.9986 12.8694C13.9986 12.6128 13.7811 12.403 13.5104 12.403Z"
                      fill="#757575"
                    />
                    <path
                      d="M13.5103 14.7933H4.48013C4.20934 14.7933 3.98779 15.0032 3.98779 15.2598C3.98779 15.5163 4.20934 15.7262 4.48013 15.7262H13.5103C13.7811 15.7262 14.0027 15.5163 14.0027 15.2598C14.0027 15.0032 13.7811 14.7933 13.5103 14.7933Z"
                      fill="#757575"
                    />
                    <path
                      d="M2.12109 5.45714V10.5023C2.12109 10.7588 2.34264 10.9687 2.61343 10.9687H7.95934C8.23013 10.9687 8.45168 10.7588 8.45168 10.5023V5.45714C8.45168 5.20061 8.23013 4.99072 7.95934 4.99072H2.61343C2.34264 4.99072 2.12109 5.20061 2.12109 5.45714ZM3.10576 5.92356H7.46701V10.0358H3.10576V5.92356Z"
                      fill="#757575"
                    />
                    <path
                      d="M13.7728 5.45715H10.2321C9.9613 5.45715 9.73975 5.66704 9.73975 5.92357C9.73975 6.18011 9.9613 6.39 10.2321 6.39H13.7728C14.0436 6.39 14.2651 6.18011 14.2651 5.92357C14.2651 5.66704 14.0477 5.45715 13.7728 5.45715Z"
                      fill="#757575"
                    />
                    <path
                      d="M13.7728 7.56384H10.2321C9.9613 7.56384 9.73975 7.77373 9.73975 8.03026C9.73975 8.2868 9.9613 8.49669 10.2321 8.49669H13.7728C14.0436 8.49669 14.2651 8.2868 14.2651 8.03026C14.2651 7.77373 14.0477 7.56384 13.7728 7.56384Z"
                      fill="#757575"
                    />
                    <path
                      d="M14.2651 10.269C14.2651 10.0125 14.0436 9.80261 13.7728 9.80261H10.2321C9.9613 9.80261 9.73975 10.0125 9.73975 10.269C9.73975 10.5256 9.9613 10.7355 10.2321 10.7355H13.7728C14.0477 10.7355 14.2651 10.5256 14.2651 10.269Z"
                      fill="#757575"
                    />
                  </svg>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Noticias</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <router-link :to="{ name: 'notificaciones' }">
              <v-list-item link>
                <v-list-item-action>
                  <v-badge
                    bordered
                    color="gns"
                    offset-x="10"
                    offset-y="10"
                    content="3"
                    :value="false"
                  >
                    <img src="@/assets/home/menu/bell.svg" />
                  </v-badge>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Notificaciones</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>
            <v-list-group>
              <template v-slot:activator>
                <v-list-item-action>
                  <img src="@/assets/home/menu/datos.svg" />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Datos</v-list-item-title>
                </v-list-item-content>
              </template>

              <router-link :to="{ name: 'personal' }">
                <v-list-item link>
                  <v-list-item-action class="pl-4">
                    <img src="@/assets/home/menu/pua.svg" />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Actividades</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>

              <router-link :to="{ name: 'declaraciones' }">
                <v-list-item link>
                  <v-list-item-action class="pl-4">
                    <img src="@/assets/home/menu/pua.svg" />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Declaraciones</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>

              <router-link :to="{ name: 'dgi' }">
                <v-list-item link>
                  <v-list-item-action class="pl-4">
                    <img src="@/assets/home/menu/pua.svg" />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>IRPF</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>

              <router-link :to="{ name: 'legajo' }">
                <v-list-item link>
                  <v-list-item-action class="pl-4">
                    <img src="@/assets/home/menu/pua.svg" />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>Legajo</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
            </v-list-group>

            <v-spacer class="py-4">
              <v-divider></v-divider>
            </v-spacer>

            <router-link :to="{ name: 'perfil' }">
              <v-list-item link>
                <v-list-item-action>
                  <img src="@/assets/home/ico-user.svg" />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>Perfil del Usuario</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </router-link>

            <v-list-item link @click="logout()">
              <v-list-item-action>
                <img src="@/assets/home/menu/logout.svg" />
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Cerrar Sesión</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-spacer class="py-4">
              <v-divider></v-divider>
            </v-spacer>

            <div class="pa-6">
              <span class="d-flex mb-4 caption grey--text">
                &copy; GNS Software
                <br />Recibos de Sueldos Digitales 2020 <br />Versión Web:
                {{ user.version_api }}
              </span>
            </div>
          </v-list>
        </v-navigation-drawer>

        <!-- Home Header (User + Menu) -->
        <v-row id="home-header" class="px-sm-4 py-sm-6">
          <v-col cols="auto" class="pr-0">
            <router-link :to="{ name: 'perfil' }">
              <div class="d-flex flex-row align-center">
                <v-avatar v-if="user.avatar" size="40">
                  <img :src="user.avatar" alt="Imagen del usuario" />
                </v-avatar>
                <v-avatar v-else size="40">
                  <img src="@/assets/home/user.jpeg" alt="Avatar del usuario" />
                </v-avatar>
                <div class="d-flex flex-column ml-4">
                  <span class="nombre-usuario d-inline-block text-truncate">{{
                    user.name
                  }}</span>
                  <span class="nombre-empresa d-inline-block text-truncate">{{
                    user.company
                  }}</span>
                </div>
              </div>
            </router-link>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto">
            <v-btn icon @click.stop="drawer = !drawer">
              <img src="@/assets/header/menu.svg" alt="icono menu" />
            </v-btn>
          </v-col>
        </v-row>

        <v-row id="home-header2" class="px-sm-5">
          <v-col cols="auto" class="pb-0">
            <img src="@/assets/home/logo-empresa.svg" alt="Logo GNS" />
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="pb-0">
            <v-btn icon color="secondary">
              <v-badge
                bordered
                color="gns"
                offset-x="10"
                offset-y="10"
                content="3"
                :value="false"
              >
                <v-icon
                  v-if="userHasNotificationActivated"
                  @click="updateNotifications(false)"
                  >far fa-bell</v-icon
                >
                <v-icon
                  v-if="!userHasNotificationActivated"
                  @click="updateNotifications(true)"
                  >far fa-bell-slash</v-icon
                >
              </v-badge>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <!--<v-progress-linear :indeterminate="true"></v-progress-linear>-->

      <!-- Provides the application the proper gutter -->
      <v-container class="pa-0">
        <!-- Page Header -->
        <header class="app-header" v-show="!navigationDrawer">
          <v-row>
            <v-col
              cols="12"
              class="d-flex justify-space-between align-center py-0"
            >
              <!-- <v-btn @click="$router.go(-1)" text dark fab class="back-arrow">
                <img src="@/assets/header/back-arrow.svg" />
              </v-btn>-->
              <div class="back-arrow-container">
                <img
                  @click="$router.go(-1)"
                  src="@/assets/header/back-arrow.svg"
                  class="back-arrow"
                />
              </div>
              <div class="title">
                <span class="d-flex align-center">
                  <img class="pr-2" src="@/assets/header/gns-icon.svg" />
                  {{ toolbarTitle }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" col-sm="12" class="company">
              <span>{{ user.company }}</span>
            </v-col>
          </v-row>
        </header>

        <!-- If using vue-router -->
        <div class="pa-sm-2 mt-5">
          <transition
            :name="transitionName"
            mode="out-in"
            @beforeLeave="beforeLeave"
            @enter="enter"
            @afterEnter="afterEnter"
          >
            <router-view></router-view>
          </transition>
        </div>
      </v-container>
    </v-main>

    <v-dialog data-app v-model="processing.show" persistent width="300">
      <v-card color="primary" dark class="loading-dialog">
        <v-card-text>
          {{ $store.state.processing.message }}
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogUninstallPush" max-width="290">
      <v-card>
        <v-card-title class="headline pb-0">
          <span>Atención</span>
          <v-spacer></v-spacer>
          <v-btn icon right @click="dialogUninstallPush = false" x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿ Realmente quieres desactivar las alertas ? Si lo haces dejarás de
          recibir nuestros avisos.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="grey" text @click="dialogUninstallPush = false">
            Cancelar
          </v-btn>

          <v-btn color="primary" text @click="unsubscribePush()">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFailInstallPush" max-width="490">
      <v-card>
        <v-card-title class="headline pb-0">
          <span>Atención</span>
          <v-spacer></v-spacer>
          <v-btn icon right @click="dialogFailInstallPush = false" x-small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p v-html="failInstallPushMessage"></p>
          <p>
            En caso de tener un navegador diferente a los especificados a
            continuación las alertas podrian NO funcionar correctamente
          </p>
          <v-simple-table light class="mt-2">
            <template v-slot:default>
              <tbody>
                <tr v-for="browser in browsers" :key="browser.code">
                  <td>{{ browser.nombre }}</td>
                  <td>
                    <a @click="configureBrowser(browser)"> Configurar </a>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog3" max-width="690">
      <v-card>
        <v-card-title class="headline pb-0">
          <span>Configuración en {{ browser.nombre }}</span>
          <v-spacer></v-spacer>
          <v-btn icon right @click="dialog3 = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <p v-html="browser.ayuda"></p>
          <v-carousel
            hide-delimiters
            v-if="browser && browser.imagenes.length > 1"
            v-model="browserImage"
            height="400"
          >
            <v-carousel-item
              v-for="(item, i) in browser.imagenes"
              :key="'picture-' + i"
            >
              <v-img height="400" :src="item" />
            </v-carousel-item>
          </v-carousel>
          <v-img
            height="400"
            v-if="browser && browser.imagenes.length == 1"
            :src="browser.imagenes[0]"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogVerify"
      v-if="browser && browser.imagenes.length == 1"
      max-width="690"
    >
      <v-card>
        <v-card-text>
          <v-img height="400" :src="browser.imagenes[0]" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="$store.state.snackbar.show"
      :color="$store.state.snackbar.color"
      :timeout="$store.state.snackbar.timeout"
      ><div v-html="$store.state.snackbar.message"></div
    ></v-snackbar>

    <!-- BOTTOM NAVIGATION -->
    <v-bottom-navigation id="bottom-navigation" fixed grow v-model="activeBtn">
      <router-link :to="{ name: 'solicitar' }">
        <v-btn>
          <span>Solicitar</span>
          <svg
            class="bottom-solicitar"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 13C19 13.5304 18.7893 14.0391 18.4142 14.4142C18.0391 14.7893 17.5304 15 17 15H5L1 19V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H17C17.5304 1 18.0391 1.21071 18.4142 1.58579C18.7893 1.96086 19 2.46957 19 3V13Z"
              stroke="#DEDEDE"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </router-link>

      <router-link :to="{ name: 'noticias' }">
        <v-btn>
          <span>Noticias</span>
          <svg
            class="bottom-noticias"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.5117 1.64807C18.2409 1.64807 18.0194 1.85796 18.0194 2.11449V14.4941C18.0194 15.8039 16.8952 16.8651 15.5167 16.8651H3.48736C2.10472 16.8651 0.984666 15.8001 0.984666 14.4941V1.1389H15.3977V13.1492C15.3977 13.4058 15.6193 13.6157 15.89 13.6157C16.1608 13.6157 16.3824 13.4058 16.3824 13.1492V0.672476C16.3824 0.415944 16.1608 0.206055 15.89 0.206055H0.492333C0.22155 0.206055 0 0.415944 0 0.672476V14.4902C0 16.3131 1.56316 17.794 3.48736 17.794H15.5126C17.4368 17.794 19 16.3131 19 14.4902V2.11449C19.0041 1.85796 18.7825 1.64807 18.5117 1.64807Z"
              fill="#DEDEDE"
            />
            <path
              d="M13.5104 3.82859C13.7811 3.82859 14.0027 3.6187 14.0027 3.36217C14.0027 3.10564 13.7811 2.89575 13.5104 2.89575H2.77749C2.50671 2.89575 2.28516 3.10564 2.28516 3.36217C2.28516 3.6187 2.50671 3.82859 2.77749 3.82859H13.5104Z"
              fill="#DEDEDE"
            />
            <path
              d="M13.5104 12.403H2.77749C2.50671 12.403 2.28516 12.6128 2.28516 12.8694C2.28516 13.1259 2.50671 13.3358 2.77749 13.3358H13.5063C13.777 13.3358 13.9986 13.1259 13.9986 12.8694C13.9986 12.6128 13.7811 12.403 13.5104 12.403Z"
              fill="#DEDEDE"
            />
            <path
              d="M13.5106 14.7933H4.48037C4.20959 14.7933 3.98804 15.0032 3.98804 15.2598C3.98804 15.5163 4.20959 15.7262 4.48037 15.7262H13.5106C13.7814 15.7262 14.0029 15.5163 14.0029 15.2598C14.0029 15.0032 13.7814 14.7933 13.5106 14.7933Z"
              fill="#DEDEDE"
            />
            <path
              d="M2.12109 5.45714V10.5023C2.12109 10.7588 2.34264 10.9687 2.61343 10.9687H7.95934C8.23013 10.9687 8.45168 10.7588 8.45168 10.5023V5.45714C8.45168 5.20061 8.23013 4.99072 7.95934 4.99072H2.61343C2.34264 4.99072 2.12109 5.20061 2.12109 5.45714ZM3.10576 5.92356H7.46701V10.0358H3.10576V5.92356Z"
              fill="#DEDEDE"
            />
            <path
              d="M13.773 5.45715H10.2323C9.96154 5.45715 9.73999 5.66704 9.73999 5.92357C9.73999 6.18011 9.96154 6.39 10.2323 6.39H13.773C14.0438 6.39 14.2654 6.18011 14.2654 5.92357C14.2654 5.66704 14.0479 5.45715 13.773 5.45715Z"
              fill="#DEDEDE"
            />
            <path
              d="M13.773 7.56384H10.2323C9.96154 7.56384 9.73999 7.77373 9.73999 8.03026C9.73999 8.2868 9.96154 8.49669 10.2323 8.49669H13.773C14.0438 8.49669 14.2654 8.2868 14.2654 8.03026C14.2654 7.77373 14.0479 7.56384 13.773 7.56384Z"
              fill="#DEDEDE"
            />
            <path
              d="M14.2654 10.269C14.2654 10.0125 14.0438 9.80261 13.773 9.80261H10.2323C9.96154 9.80261 9.73999 10.0125 9.73999 10.269C9.73999 10.5256 9.96154 10.7355 10.2323 10.7355H13.773C14.0479 10.7355 14.2654 10.5256 14.2654 10.269Z"
              fill="#DEDEDE"
            />
          </svg>
        </v-btn>
      </router-link>

      <router-link :to="{ name: 'liquidaciones' }">
        <v-btn>
          <span>Recibos</span>
          <svg
            class="bottom-recibos"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.65814 2.80505H2.70181C2.25046 2.80505 1.8176 2.98435 1.49845 3.3035C1.1793 3.62265 1 4.05552 1 4.50686V16.4195C1 16.8709 1.1793 17.3037 1.49845 17.6229C1.8176 17.942 2.25046 18.1213 2.70181 18.1213H14.6145C15.0658 18.1213 15.4987 17.942 15.8178 17.6229C16.137 17.3037 16.3163 16.8709 16.3163 16.4195V10.4632"
              stroke="#DEDEDE"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M15.04 1.52868C15.3785 1.19017 15.8376 1 16.3163 1C16.795 1 17.2542 1.19017 17.5927 1.52868C17.9312 1.8672 18.1214 2.32631 18.1214 2.80504C18.1214 3.28377 17.9312 3.74289 17.5927 4.0814L9.50909 12.165L6.10547 13.0159L6.95637 9.61228L15.04 1.52868Z"
              stroke="#DEDEDE"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </v-btn>
      </router-link>

      <router-link :to="{ name: 'home' }">
        <v-btn>
          <span>Home</span>
          <svg
            class="bottom-home"
            width="20"
            height="20"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.48 0.820027L0.957 5.41503C0.362397 5.77844 -0.000173186 6.42516 6.20603e-08 7.12203V16C6.20603e-08 17.1046 0.895431 18 2 18H16C17.1046 18 18 17.1046 18 16V7.12203C18 6.42571 17.6379 5.7795 17.044 5.41603L9.522 0.820027C9.20211 0.62477 8.79989 0.62477 8.48 0.820027ZM6 11C6 10.4477 6.44772 10 7 10H11C11.5523 10 12 10.4477 12 11V14C12 14.5523 11.5523 15 11 15H7C6.44772 15 6 14.5523 6 14V11Z"
              fill="#DEDEDE"
            />
          </svg>
        </v-btn>
      </router-link>

      <v-btn @click="openContactForm()">
        <span>Contactar</span>
        <v-icon class="bottom-home">contact_support</v-icon>
        <!-- <svg
          class="bottom-home"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.48 0.820027L0.957 5.41503C0.362397 5.77844 -0.000173186 6.42516 6.20603e-08 7.12203V16C6.20603e-08 17.1046 0.895431 18 2 18H16C17.1046 18 18 17.1046 18 16V7.12203C18 6.42571 17.6379 5.7795 17.044 5.41603L9.522 0.820027C9.20211 0.62477 8.79989 0.62477 8.48 0.820027ZM6 11C6 10.4477 6.44772 10 7 10H11C11.5523 10 12 10.4477 12 11V14C12 14.5523 11.5523 15 11 15H7C6.44772 15 6 14.5523 6 14V11Z"
            fill="#DEDEDE"
          />
        </svg> -->
      </v-btn>
    </v-bottom-navigation>

    <push-prompt
      :show="showPushWindow"
      @cancelAction="cancelPush()"
      @activeAction="installPush()"
    ></push-prompt>
    <contacto-form
      :show-contact-in="contactoDialog"
      @contactFormClosed="contactoDialog = false"
    ></contacto-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import PushPrompt from "@/components/push/Push.vue";
import ContactoForm from "@/components/contacto/ContactoForm.vue";
import pushMixin from "@/mixins/push";
import { getAnsweredPushNotification } from "@/utils/auth";
import { updateNotifcation } from "@/api/core";

const DEFAULT_TRANSITION = "fade";

export default {
  name: "Layout",
  components: {
    PushPrompt,
    ContactoForm,
  },
  mixins: [pushMixin],
  data: () => ({
    contactoDialog: false,
    dialog3: false,
    dialogUninstallPush: false,
    //drawerNotificacions: null,
    drawer: null,
    iconoNotificaciones: [],
    //itemsNotificaciones: [],
    prevHeight: 0,
    transitionName: DEFAULT_TRANSITION,
    showPushWindow: false,
    browsers: [],
    browser: false,
    browserImage: 0,
    answeredPushNotification: false,
    dialogVerify: true,
  }),

  computed: {
    ...mapGetters([
      "processing",
      "backButton",
      "navigationDrawer",
      "toolbarTitle",
      "user",
      "companies",
      "activeNav",
      //"answeredPushNotification",
      "collections",
    ]),

    userHasNotificationActivated() {
      return this.user.notifications_config;
    },

    activeSolicitarClass() {
      return this.$route.name == "solicitar" ? "v-btn--active" : "";
    },

    activeHomeClass() {
      return this.$route.name == "home" ? "v-btn--active" : "";
    },

    activeLiquidacionesClass() {
      return [
        "liquidaciones",
        "detalle_liquidacion",
        "detalle_calculo",
      ].includes(this.$route.name)
        ? "v-btn--active"
        : "";
    },

    activeNoticiasClass() {
      return this.$route.name == "noticias" ? "v-btn--active" : "";
    },

    activeBtn: {
      get: function () {
        return this.activeNav;
      },
      set: function (c) {
        this.$store.commit("setActiveNav", c);
      },
    },
  },

  created() {
    this.$store.commit("setSnackBar", {
      show: false,
    });

    this.browsers = this.collections.browsers;
    this.initSettingPush();

    this.$router.beforeEach((to, from, next) => {
      let transitionName = to.meta.transitionName || from.meta.transitionName;

      if (transitionName === "slide") {
        const toDepth = to.path.split("/").length;
        const fromDepth = from.path.split("/").length;
        transitionName = toDepth < fromDepth ? "slide-right" : "slide-left";
      }

      this.transitionName = transitionName || DEFAULT_TRANSITION;

      next();
    });
  },

  mounted() {},

  methods: {
    openContactForm() {
      this.contactoDialog = true;
    },

    initSettingPush() {
      this.answeredPushNotification = getAnsweredPushNotification();
      this.showPushWindow =
        !this.user.notifications_config && !this.answeredPushNotification;
    },

    configureBrowser(browser) {
      this.browser = browser;
      this.dialog3 = true;
    },

    cancelPush() {
      this.setShowPushWindow(false);
      this.setUpdateWindowAskPush(true);
    },

    installPush() {
      this.setShowPushWindow(false);
      this.pushInstall();
      this.setUpdateWindowAskPush(true);
    },

    unsubscribePush() {
      this.pushUninstall();
      this.dialogUninstallPush = false;
      this.setUpdatePush(false);
      this.pushActive = false;
    },

    confirmPushUninstall() {
      this.dialogUninstallPush = true;
    },

    setUpdatePush(value) {
      this.$store.dispatch("user/update_push_notification", {
        val: value,
      });
    },

    setUpdateWindowAskPush(value) {
      this.$store.dispatch("user/update_push_notification_window", {
        val: value,
      });
    },

    setShowPushWindow(val) {
      this.showPushWindow = val;
    },

    logout() {
      this.$store.dispatch("user/logout").then(() => {
        this.$router.push(`/login?redirect=${this.$route.fullPath}`);
      });
    },

    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },

    enter(element) {
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },

    afterEnter(element) {
      element.style.height = "auto";
    },

    async updateNotifications(activate) {
      this.$store.commit("startProcessing", {
        value: true,
        message: "Actualizando...",
      });

      if (activate) {
        this.pushInstall();
      }

      let data = await updateNotifcation({ status: activate });

      if (Object.prototype.hasOwnProperty.call(data, "token")) {
        this.$store.dispatch("user/refresh_token", {
          token: data.token,
        });
      }

      this.$store.commit("stopProcessing");
    },
  },
};
</script>

<style>
@media (max-width: 380px) {
  #home-header .nombre-usuario,
  #home-header .nombre-empresa {
    max-width: 45vw;
  }

  /* For bottom navbar, small device, border */
  .v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before,
  .v-item-group.v-bottom-navigation .v-btn.v-btn--active:not(:active):before,
  .v-btn:not(.v-btn--text):not(.v-btn--outlined):hover:before {
    opacity: 0;
  }
  .v-ripple__container {
    display: none !important;
  }
  #bottom-navigation .v-btn {
    min-width: 60px !important;
  }
  .app-header {
    padding: 40px 13px !important;
  }
}
</style>
