import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';

/**
 * Layzloading will create many files and slow on compiling, so best not to use lazyloading on devlopment.
 * The syntax is lazyloading, but we convert to proper require() with babel-plugin-syntax-dynamic-import
 * @see https://doc.laravue.dev/guide/advanced/lazy-loading.html
 */

Vue.use(Router);

/* Layout */
import Layout from '@/views/layout';
import { isAuthenticated } from '@/utils/auth';

/* Router for modules */

/**
 * Sub-menu only appear when children.length>=1
 * @see https://doc.laravue.dev/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin', 'editor']   Visible for these roles only
    permissions: ['view menu zip', 'manage user'] Visible for these permissions only
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    noCache: true                if true, the page will no be cached(default is false)
    breadcrumb: false            if false, the item will hidden in breadcrumb (default is true)
    affix: true                  if true, the tag will affix in the tags-view
  }
**/

const beforeEnter = (to, from, next) => {
  if (isAuthenticated()) {
    next({path: '/'});
  } else {
    next();
  }
};

const beforeProfileEnter = (to, from, next) => {
  if (isAuthenticated()) {
    const dataVerified = true;
    const hasToCompleteProfile = ( store.state.user.user.estado == 0 || !dataVerified )
    if( hasToCompleteProfile && to.name != 'perfil') {
      next({ name: 'perfil' })
    } else {
      next()
    }
  } else {
    next();
  }
};

export const constantRoutes = [
  {
    path: '/login',
    name: "login",
    component: () => import('@/views/login/index'),
    hidden: true,
    meta: { title: 'Login', noCache: true },
    beforeEnter: (to, from, next) => beforeEnter(to, from, next)
  },
  {
    path: '/email-verificado',
    name: "email-verificado",
    component: () => import('@/views/login/verify'),
    hidden: true,
    meta: { title: 'E-mail Verificado', noCache: true },
    //beforeEnter: (to, from, next) => beforeEnter(to, from, next)
  },
  {
    path: '/reset',
    name: "reset",
    component: () => import('@/views/login/reset'),
    hidden: true,
    meta: { title: 'Recuperar contraseña', noCache: true },
    beforeEnter: (to, from, next) => beforeEnter(to, from, next)
  },
  {
    path: '/admin',
    name: "admin",
    component: () => import('@/views/login/admin'),
    hidden: true,
    meta: { title: 'Admin', noCache: true },
    beforeEnter: (to, from, next) => beforeEnter(to, from, next)
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404'),
    hidden: true,
  },
  {
    path: '/401',
    component: () => import('@/views/error-page/401'),
    hidden: true,
  },
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: () => import('@/views/home/index'),
        name: 'home',
        meta: { title: 'Inicio', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/empresa',
        component: () => import('@/views/empresa/index'),
        name: 'empresa',
        meta: { title: 'Empresa', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/empresa/nueva',
        component: () => import('@/views/empresa/nueva'),
        name: 'empresa_nueva',
        meta: { title: 'Nueva Empresa', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/empresa/manual',
        component: () => import('@/views/empresa/manual'),
        name: 'empresa_configuracion_manual',
        meta: { title: 'Nueva Empresa - Configuración Manual', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/noticias',
        component: () => import('@/views/noticias/index'),
        name: 'noticias',
        meta: { title: 'Noticias', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/liquidaciones',
        component: () => import('@/views/liquidaciones/index'),
        name: 'liquidaciones',
        meta: { title: 'Liquidaciones', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/liquidaciones/pdf/:id',
        component: () => import('@/views/liquidaciones/pdf'),
        name: 'detalle_calculo',
        meta: { title: 'Descarga Pdf', icon: 'documentation', noCache: true, transitionName: 'slide' },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/liquidaciones/:id',
        component: () => import('@/views/liquidaciones/show'),
        props: true,
        name: 'detalle_liquidacion',
        meta: { title: 'Detalle de liquidación', icon: 'documentation', noCache: true, transitionName: 'slide' },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/notificaciones',
        component: () => import('@/views/notificaciones/index'),
        name: 'notificaciones',
        meta: { title: 'Notificaciones', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/notificaciones/pdf/:id',
        component: () => import('@/views/notificaciones/pdf'),
        name: 'notificacion_pdf',
        meta: { title: 'Descarga Pdf', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/pdf',
        component: () => import('@/views/pdf/index'),
        name: 'pdf',
        meta: { title: 'Pdf', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/solicitar',
        component: () => import('@/views/solicitar/index'),
        name: 'solicitar',
        meta: { title: 'Solicitar', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/adelantos',
        component: () => import('@/views/adelantos/index'),
        name: 'adelantos',
        meta: { title: 'Adelantos', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/informes',
        component: () => import('@/views/informes/index'),
        name: 'informes',
        meta: { title: 'Informes', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/declaraciones',
        component: () => import('@/views/declaraciones/index'),
        name: 'declaraciones',
        meta: { title: 'Declaraciones', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/dgi',
        component: () => import('@/views/dgi/index'),
        name: 'dgi',
        meta: { title: 'DGI', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/licencias',
        component: () => import('@/views/licencias/index'),
        name: 'licencias',
        meta: { title: 'Licencias', icon: 'fas fa-umbrella-beach', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/legajo',
        component: () => import('@/views/legajo/index'),
        name: 'legajo',
        meta: { title: 'Legajo', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/historial-laboral',
        component: () => import('@/views/historial-laboral/index'),
        name: 'historial_laboral',
        meta: { title: 'Historial Laboral', icon: 'documentation', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/personal',
        component: () => import('@/views/personal/index'),
        name: 'personal',
        meta: { title: 'Datos Personales y Actividades', icon: 'user', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/perfil',
        component: () => import('@/views/perfil/index'),
        name: 'perfil',
        meta: { title: 'Perfil Usuario', icon: 'user', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
      {
        path: '/ayuda',
        component: () => import('@/views/ayuda/index'),
        name: 'ayuda',
        meta: { title: 'Ayuda', icon: 'app', noCache: true },
        beforeEnter: (to, from, next) => beforeProfileEnter(to, from, next)
      },
    ],
  }
];

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
});

const router = createRouter();

export default router;
