export default {
  /**
   * @type {String}
   */
  title: 'Recibos Digitales',

  /**
   * @type {string | array} 'production' | ['production','development']
   * @description Need show err logs component.
   * The default is only used in the production env
   * If you want to also use it in dev, you can pass ['production','development']
   */
  errorLog: 'production',
};
