import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import es from 'vuetify/es5/locale/es';
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.css'
//import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'mdiSvg' || 'md' || 'fa' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    dark: false,
    default: 'light',
    disable: false,
    options: {
      cspNonce: undefined,
      customProperties: true,
      minifyTheme: undefined,
      themeCache: undefined,
    },
    themes: {
      light: {
        primary: '#FF7F04',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
        // licencia: '#ff7f04',
        sueldo: '#8204ff',
        // aguinaldo: '#79b100',
        fe: '#78b5fd',
        personal: '#ff7f04',
        gns: '#ff3301',
        hada: '#A0D061',
        forma: '#A774FF',
        pendiente: '#9C80F7',
        aprobado: '#A0D063',
        rechazado: '#FF3301',
        haberes: '#fbfdf8',
        descuentos: '#fef4f5'
      },
      dark: {
        primary: '#272727',
        secondary: '#424242',
        accent: '#FF4081',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FB8C00',
        // licencia: '#ff7f04',
        sueldo: '#8204ff',
        // aguinaldo: '#79b100',
        fe: '#78b5fd',
        personal: '#ff7f04',
        gns: '#ff3301',
        hada: '#A0D061',
        forma: '#A774FF',
        pendiente: '#9C80F7',
        aprobado: '#A0D063',
        rechazado: '#FF3301',
        haberes: '#fbfdf8',
        descuentos: '#fef4f5'
      },
    },
  }
});
