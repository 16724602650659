const getters = {
  processing: state => state.processing,
  collections: state => state.dict.collections,
  local_api_url: state => state.local_api_url,
  backButton: state => state.backButton,
  navigationDrawer: state => state.navigationDrawer,
  toolbarTitle: state => state.toolbarTitle,
  user: state => state.user.user,
  companies: state => state.user.companies,
  activeNav: state => state.activeNav,
  //settingPush: state => state.user.settingPush,
  //answeredPushNotification: state => state.user.answeredPushNotification
};
export default getters;
