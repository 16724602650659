import request from '@/utils/request';

export function getLiquidacionesFilters() {
  return request({
    url: '/liquidaciones/filters',
    method: 'get'
  });
}

export function getEmpresas() {
  return request({
    url: '/empresas',
    method: 'get'
  });
}

export function getLiquidaciones() {
  return request({
    url: '/liquidaciones',
    method: 'get'
  });
}

export function getLiquidacionesSearch(filters) {
  return request({
    url: '/liquidaciones/search',
    method: 'post',
    data: filters,
    //headers: {'X-Requested-With': 'XMLHttpRequest'},
  });
}

export function getDetalleLiquidacion(id) {
  return request({
    url: '/liquidaciones/detalle/'+id,
    method: 'get'
  });
}

export function getTiposLiquidaciones() {
  return request({
    url: '/liquidaciones/tipos',
    method: 'get'
  });
}

export function getDetalleCalculo(id, stream) {
  return request({
    url: '/liquidaciones/detalle-calculo?idDetaCalc=' + id + '&stream=' + stream,
    method: 'get',
    responseType: stream ? 'arraybuffer' : 'json'
  });
}

export function getNovedades(page, mode) {
  return request({
    url: '/novedades?page=' + page + '&mode=' + mode,
    method: 'get'
  });
}

export function getNotificaciones() {
  return request({
    url: '/notificaciones',
    method: 'get'
  });
}

export function getNotificacionesSearch(filters) {
  return request({
    url: '/notificaciones/search',
    method: 'post',
    data: filters
  });
}

export function getInformes() {
  return request({
    url: '/informes',
    method: 'get'
  });
}

export function getLegajoData() {
  return request({
    url: '/legajo',
    method: 'get'
  })
}

export function getPerfil() {
  return request({
    url: '/perfil',
    method: 'get'
  })
}

export function getPerfilActividad() {
  return request({
    url: '/perfil/actividad',
    method: 'get'
  });
}

export function getAdelantos(query) {
  return request({
    url: '/adelantos',
    method: 'get',
    params: query
  })
}

export function getLicencias(query) {
  return request({
    url: '/licencias',
    method: 'get',
    params: query
  })
}

export function solicitarLicencia(params) {
  return request({
    url: '/licencias',
    method: 'post',
    data: params
  });
}

export function actualizarLicencia(params) {
  return request({
    url: '/licencias',
    method: 'put',
    data: params
  });
}

export function eliminarLicencia(params) {
  return request({
    url: '/licencias',
    method: 'delete',
    data: params
  });
}

export function solicitarAdelanto(params) {
  return request({
    url: '/adelantos',
    method: 'post',
    data: params
  });
}

export function actualizarAdelanto(params) {
  return request({
    url: '/adelantos',
    method: 'put',
    data: params
  });
}

export function eliminarAdelanto(params) {
  return request({
    url: '/adelantos',
    method: 'delete',
    data: params
  });
}

export function updateNotificacion(params) {
  return request({
    url: '/notificaciones/update',
    method: 'put',
    data: params
  });
}

export function updatePerfil(params) {
  return request({
    url: '/perfil/update',
    method: 'put',
    data: params
  });
}

export function updateAvatar(params) {
  return request({
    url: '/perfil/update-avatar',
    method: 'put',
    data: params
  });
}

export function uploadAvatar(params) {
  return request({
    url: '/perfil/upload',
    method: 'post',
    data: params,
    headers: {'Content-Type' : 'multipart/form-data'}
  });
}

export function pushSubscribe(params, device) {
  params = params.toJSON()
  var postParams = Object.assign(params, device)
  return request({
    url: '/push',
    method: 'post',
    data: postParams
  });
}

export function getCollections() {
  return request({
    url: '/collections',
    method: 'get'
  })
}

export function getDeclaraciones() {
  return request({
    url: '/declaraciones',
    method: 'get'
  })
}

export function getDevicesList() {
  return request({
    url: '/devices',
    method: 'get'
  })
}

export function deleteDevice(params) {
  return request({
    url: '/push/delete',
    method: 'post',
    data: params
  });
}

export function sendMePush() {
  return request({
    url: '/push/test',
    method: 'post',
    data: {}
  });
}

export function pushUnsubscribe() {
  return request({
    url: '/push/desactivate',
    method: 'post',
    data: {}
  });
}

export function sendContact(params) {
  return request({
    url: '/contacto',
    method: 'post',
    data: params
  });
}

export function sendPublicContact(params) {
  return request({
    url: '/contacto-gns',
    method: 'post',
    data: params
  });
}

export function updateEstadoLiquidacion(params) {
  return request({
    url: '/liquidaciones/cambiar-estado',
    method: 'post',
    data: params
  });
}

export function updateNotifcation(params) {
  return request({
    url: '/perfil/notification',
    method: 'post',
    data: params
  });
}

export function updateData(params) {
  return request({
    url: '/perfil/update-data',
    method: 'put',
    data: params
  });
}

export function updatePassword(params) {
  return request({
    url: '/perfil/update-password',
    method: 'put',
    data: params
  });
}

export function getMedia(q) {
  return request({
    url: '/media?q='+q,
    method: 'get'
  });
}

export function generarCodigoVerificacion(params) {
  return request({
    url: '/perfil/generate-code',
    method: 'post',
    data: params
  });
}

export function requestPerfilSendCode(params) {
  return request({
    url: '/perfil/verify-mobile',
    method: 'post',
    data: params
  });
}

export function requestVerificarEmail(params) {
  return request({
    url: '/perfil/verify-email',
    method: 'post',
    data: params
  });
}

export function requestRefreshEmail(params) {
  return request({
    url: '/perfil/refresh-email',
    method: 'post',
    data: params
  });
}

export function resetVerification(params) {
  return request({
    url: '/perfil/reset-verification',
    method: 'post',
    data: params
  });
}
