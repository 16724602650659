import { pushSubscribe, pushUnsubscribe } from "@/api/core";
import { deviceDetect } from 'mobile-device-detect';

export default {
  
  created: function () {
    //this.pushSubscribeUser();
  },
  
  data: () => ({
    pushActive: false,
    dialogFailInstallPush: false,
    failInstallPushMessage: null
  }),

  methods: {

    pushUninstall() {
        /*if (! ("serviceWorker" in navigator)){
            //service worker isn't supported
            return;
        }

        //don't use it here if you use service worker
        //for other stuff.
        if (!("PushManager" in window)) {
            console.log('return not pushmanager')
            //push isn't supported
            return;
        }

        const swReady = navigator.serviceWorker.ready;

        if (!swReady) {
            console.log('not ready')
            return;
        }

        navigator.serviceWorker.ready.then(function(reg) {
            reg.pushManager.getSubscription().then(function(subscription) {
              subscription.unsubscribe().then(function(successful) {
                // You've successfully unsubscribed
                console.log(successful)
              }).catch(function(e) {
                // Unsubscription failed
                console.log(e)
              })
            })
          });*/
          pushUnsubscribe()
    },

    setFailInstallPush(message) {
        this.dialogFailInstallPush = true
        this.failInstallPushMessage = message
    },

    pushInstall() {
        //https://webpushdemo.azurewebsites.net/ 
        if (! ("serviceWorker" in navigator)){
            this.setFailInstallPush('Las alertas no pueden ser configuradas en tu dispositivo. Aconsejamos utilizar Chrome para evitar inconvenientes')
            return;
        }

        //don't use it here if you use service worker
        //for other stuff.
        if (!("PushManager" in window)) {
            this.setFailInstallPush('Las alertas no pueden ser configuradas en tu dispositivo. Aconsejamos utilizar Chrome para evitar inconvenientes')
            return;
        }

        const swReady = navigator.serviceWorker.ready;

        if (!swReady) {
            this.setFailInstallPush('Las alertas no pueden ser configuradas en tu dispositivo. Aconsejamos utilizar Chrome para evitar inconvenientes')
            return;
        }

        new Promise(function (resolve, reject) {
            const permissionResult = Notification.requestPermission(function (result) {
                resolve(result);
            });

            if (permissionResult) {
                permissionResult.then(resolve, reject);
            }
        })
        .then((permissionResult) => {
            if (permissionResult !== 'granted') {
                //throw new Error('We weren\'t granted permission.');
                this.setFailInstallPush('Debes otorgar los permisos para poder recibir nuestras alertas')
                return false
            }
            this.pushSubscribeUser();
        });
    },

    pushSubscribeUser() {
        navigator.serviceWorker.ready
            .then((registration) => {
                const subscribeOptions = {
                    userVisibleOnly: true,
                    applicationServerKey: this.urlBase64ToUint8Array(
                        'BMzBYNGGA7Y6omLOBvJpmAVgWMiHatYMouxTTOMZxe9_fsinxnCiVVRljqO9P1-JgSJR5fepW-R-ZdwcwkhZj7E'
                    )
                };
                return registration.pushManager.subscribe(subscribeOptions);
            })
            .then((pushSubscription) => {
                //console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
                this.pushStorePushSubscription(pushSubscription);
            })
            .catch( () => {
                this.setFailInstallPush('Debes otorgar los permisos correctos desde la configuración de tu navegador para recibir nuestras alertas')
            })
    },

    pushStorePushSubscription(pushDataSubscription) {
        this.$store.dispatch("user/update_push_notification", {
            val: true
        })
        let deviceParam = {
            device: deviceDetect()
        }
        pushSubscribe(pushDataSubscription, deviceParam)
        this.pushActive = true
    },

    urlBase64ToUint8Array(base64String) {
        var padding = '='.repeat((4 - base64String.length % 4) % 4);
        var base64 = (base64String + padding)
            .replace(/-/g, '+')
            .replace(/_/g, '/');

        var rawData = window.atob(base64);
        var outputArray = new Uint8Array(rawData.length);

        for (var i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }
  }
}