<template>
  <div class="text-center">
    <v-dialog
      v-model="showContactIn"
      max-width="480"
      :persistent="false"
      @click:outside="closeDialog()"
    >
      <v-card>
        <v-card-title class="headline pb-2 mb-0">
          <span>Comunícate con tu empresa</span>
          <v-spacer></v-spacer>
          <v-btn x-small icon right @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        
        <v-card-subtitle v-if="contacto.tipo == 'rrhh' && user.company_email">
          {{ user.company_email }}
        </v-card-subtitle>

        <v-card-text class="pb-0 pt-4">
          <v-row justify="space-between">
            <v-col cols="12" md="12" class="pt-0">
              <v-form ref="contactoForm" autocomplete="off">
                  <v-text-field
                    label="Correo electrónico"
                    type="email"
                    v-model="contacto.email"
                    :rules="[rules.required, rules.email]"
                    dense
                    class="pt-2"
                  ></v-text-field>

                  <v-text-field
                    v-model="contacto.celular"
                    :rules="[rules.required]"
                    type="number"
                    label="Celular"
                    dense
                    class="pt-2"
                  ></v-text-field>

                  <v-textarea
                    rows="5"
                    auto-grow
                    label="Mensaje"
                    :rules="[rules.required]"
                    v-model="contacto.mensaje"
                  ></v-textarea>
              </v-form>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            type="button"
            @click="send()"
            color="primary"
            class="btn mt-0 mb-6"
            small
            :disabled="sending"
            :loading="sending"
            >Enviar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="profile.dialog"
      max-width="390"
    >
      <v-card>
        <v-card-title class="headline pb-0">
            <span>Atención</span>
            <v-spacer></v-spacer>
            <v-btn
                icon
                right
                @click="profile.dialog = false"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>          
        </v-card-title>

        <v-card-text>
          Los datos en su perfil son diferentes a los que está enviando. ¿ Desea actualizarlos ?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="grey"
            text
            @click="profile.dialog = false"
          >
            No
          </v-btn>

          <v-btn
            color="primary"
            text
            @click="updateProfile()"
          >
            Si
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { sendContact, updateData } from "@/api/core";

export default {
  props: {
    showContactIn: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      profile: {
        dialog: false,
        email: '',
        celular: ''
      },
      contacto: {
        tipo: "rrhh",
        celular: "",
        email: "",
        mensaje: "",
      },
      disabledCelular: false,
      disabledEmail: false,
      sending: false,
      rules: {
        required: (value) => !!value || "Requerido.",
        email: (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "E-mail incorrecto.";
        },
      },
    };
  },

  created() {

  },

  computed: {
    ...mapGetters(["user"]),
  },

  mounted() {
    this.contacto.email = this.user.email
    this.contacto.celular = this.user.telefono_celular
  },

  methods: {
    async updateProfile() {
      try {
        let params = {
          email: this.profile.email,
          celular: this.profile.celular
        }
        let data = await updateData(params)
        this.profile.dialog = false
        this.$store.dispatch("user/refresh_token", {
          token: data.token,
        })
      } catch (e) {
        console.log(e)
        this.profile.dialog = false
      }
    },

    openProfileDialog() {
      this.profile.dialog = true
    },

    async send() {
      try {
        if (!this.$refs.contactoForm.validate()) {
            return false
        }

        /*let mustUpdateProfile = this.hasDifferentDataProfile()
        if(mustUpdateProfile) {
          this.profile.email = this.contacto.email
          this.profile.celular = this.contacto.celular
          this.openProfileDialog()
        }*/

        this.sending = true
        const response = await sendContact(this.contacto)
        this.sending = false

        if(!response.send) {
          this.$store.commit("setSnackBar", {
              message: response.message,
              show: true,
              color: "#cc0605",
          })
        } else {
          let msg = (this.contacto.tipo == 'rrhh' ? 'Tu consulta fue enviada al departameneto de Recusos Humanos de tu empresa ' + this.user.company_email : 'Tu consulta tecnica ha sido enviada a GNS')
        
          this.$store.commit("setSnackBar", {
              message: msg,
              show: true,
              color: "#008f39",
          })
        }
        this.cleanForm()
        this.closeDialog()
      } catch (e) {
        this.sending = false
      }
    },

    hasDifferentDataProfile() {
      return (this.user.email != this.contacto.email) || (this.user.telefono_celular != this.contacto.celular)
    },

    closeDialog() {
      this.$emit("contactFormClosed")
    },

    cleanForm() {
      this.$refs.contactoForm.resetValidation()
      this.contacto.mensaje = ''
      this.contacto.tipo = "rrhh"
    },
  },
};
</script>
