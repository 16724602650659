<template>
  <v-app id="inspire">
    <!-- If using vue-router -->
    <router-view></router-view>
  </v-app>
</template>

<script>
//import { mapGetters } from "vuex";
import axios from 'axios';
import store from '@/store';
import { isAuthenticated } from '@/utils/auth';
//import InstallPrompt from "@/components/install/Prompt.vue"

export default {
  props: {
    source: String,
  },
  components: {
    //InstallPrompt
  },
  data: () => ({
    drawer: null
  }),

  created () {
    //https://github.com/duskload/mobile-device-detect
    //<install-prompt></install-prompt>
    this.$vuetify.theme.dark = false
    axios.get("config/config.json").then(response => {
      store.commit('setLocalApiUrl', response.data.BASE_URL)
    });
    axios.get("config/areas.json").then(response => {
      store.commit('dict/SET_AREAS', response.data)
    });
    axios.get("config/browsers.json").then(response => {
      store.commit('dict/SET_BROWSERS', response.data)
    });
    if(isAuthenticated()) {
      this.$store.dispatch("dict/initCollections")
    }
  },
  
  computed: {

  },

  methods: {

  },
}
</script>
