import router from './router';
import { getToken } from '@/utils/auth'; // get token from cookie
import getPageTitle from '@/utils/get-page-title';

const whiteList = ['/login', '/auth-redirect', '/404', '/admin', '/401', '/reset', '/email-verificado']; // no redirect whitelist

router.beforeEach(async(to, from, next) => { 
  // set page title
  document.title = getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const hasToken = getToken()

  if (hasToken) {
    if(to.path === '/login' || to.path === '/admin') {
      next({ path: '/' })
    } else {
      next()
    }
  } else {
    
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next();
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`);
    }

  }
});

router.afterEach(() => {
  // finish progress bar
  //NProgress.done();
});
