import { login } from '@/api/auth';
import { getToken, setToken, removeToken, setPushNotification, setAnsweredPushNotification } from '@/utils/auth';
//import store from '@/store';
import decode from 'jwt-decode';

const user = {
  'name': null,
  'email': null,
  'perfil': null,
  'avatar': null,
  'estado': null,
  'version_api': null,
  'company_news': null,
  'company': null,
  'company_razon_social': null,
  'company_rut': null,
  'company_logo': null,
  'company_email': null,
  'company_grupo': null,
  'mobile_verified': null,
  'email_verified': null
};

const state = {
  token: getToken(),
  companies: [],
  user: Object.assign({}, user),
  //settingPush: getPushNotification(),
  //answeredPushNotification: getAnsweredPushNotification()
};

const parseJwt = (token) => {
  try {
    return decode(token)
  } catch (e) {
    console.log(e)
    return null;
  }
};

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_USER: (state, payload) => {
    state.user = Object.assign({}, payload)
  },
  SET_COMPANIES: (state, c) => {
    state.companies = c
  },
  SET_PUSH: (state, val) => {
    state.settingPush = val
  },
  SET_ANSWERED_PUSH: (state, val) => {
    state.answeredPushNotification = val
  },
  SET_VERIFIED_MOBILE: (state, val) => {
    state.user.mobile_verified = val;
  },
  SET_VERIFIED_EMAIL: (state, val) => {
    state.user.email_verified = val;
  }
};

const actions = {
  updateVerifiedMobile({ commit }, { val }) {
    commit('SET_VERIFIED_MOBILE', val)
  },
  
  updateVerifiedEmail({ commit }, { val }) {
    commit('SET_VERIFIED_EMAIL', val)
  },

  // user login
  login({ commit }, { url, userInfo }) {
    const { username, password, empresa } = userInfo;

    return new Promise((resolve, reject) => {
      login(url, { usuario: username.trim(), password: password, cod_emp: empresa })
        .then(response => {
          let user_data = parseJwt(response.data.access_token)
          commit('SET_TOKEN', response.data.access_token)
          commit('SET_USER', user_data.info)
          setToken(response.data.access_token)
          resolve()
        })
        .catch(error => {
          console.log(error)
          reject(error);
        });
    });
  },

  refresh_token({ commit }, { token }) {
    let user_data = parseJwt(token)
    commit('SET_TOKEN', token)
    commit('SET_USER', user_data.info)
    setToken(token)
  },

  update_push_notification({ commit, state }, { val }) {
    let sufix = state.user.id_usuario + '_' + state.user.company_cod
    commit('SET_PUSH', val)
    setPushNotification(val, sufix)
  },

  update_push_notification_window({ commit }, { val }) {
    commit('SET_ANSWERED_PUSH', val)
    setAnsweredPushNotification(val)
  },

  // get user info
  /*getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo(state.token)
        .then(response => {
          const { data } = response;

          if (!data) {
            reject('Verification failed, please Login again.');
          }

          const { name, id } = data;

          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },*/

  // user logout
  logout({ commit }) {
    /*{ commit, state } return new Promise((resolve, reject) => {
      logout(state.token)
        .then(() => {
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });*/
    commit('SET_TOKEN', '')
    commit('SET_USER', Object.assign({}, user));
    commit('SET_COMPANIES', []);
    removeToken()
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_USER', Object.assign({}, user));
      commit('SET_COMPANIES', []);
      removeToken();
      resolve();
    });
  },
};

export default {
  name: 'user',
  namespaced: true,
  state,
  mutations,
  actions,
};
