import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const state = {
  loaded: false,
  
  alert: {
    type: 'success',
    show: false,
    message: ''
  },
  snackbar: {
    color: '',
    timeout: 3000,
    show: false,
    message: ''
  },

  processing: {
    show: false,
    message: 'Procesando...'
  },

  local_api_url: null,

  backButton: false,
  toolbarTitle: 'RECIBOS DIGITALES',
  navigationDrawer: false,
  activeNav: 0
}

const mutations = {
  setActiveNav: (state, val) => {
    state.activeNav = val
  },
  setNavigationDrawer: (state, val) => {
    state.navigationDrawer = val
  },
  setBackButton: (state, val) => {
    state.backButton = val
  },
  setToolbarTitle: (state, val) => {
    state.toolbarTitle = val
  },
  startProcessing: (state, data) => {
    state.processing.show = data.value || true
    let message = data.message || ''
    if(message != '') {
      state.processing.message = message
    }
  },
  stopProcessing: (state) => {
    state.processing.show = false;
  },
  setLoaded: (state, loaded) => {
    state.loaded = loaded;
  },
  setAlertMessage: (state, data) => {
    state.alert.type = data.type;
    state.alert.show = data.show;
    state.alert.message = data.message;
    setTimeout(() => {
      state.alert.type = 'success';
      state.alert.show = false;
      state.alert.message = '';
    }, data.timeout);
  },
  setSnackBar: (state, data) => {
    state.snackbar.color = data.color || '';
    state.snackbar.show = data.show;
    state.snackbar.message = data.message;
    state.snackbar.timeout = data.timeout || state.snackbar.timeout;
    /*setTimeout(() => {
      state.alert.type = 'success';
      state.alert.show = false;
      state.alert.message = '';
    }, data.timeout);*/
  },
  setLocalApiUrl:(state, url) => {
    state.local_api_url = url
  }
}

// Import all of the resource store files.
function loadStores() {
  const context = require.context('./modules', true, /\.js$/i);
  return context.keys()
    .map(context)         // import module
    .map(m => m.default)  // get `default` export from each resolved module
}

const modules = {};
loadStores().forEach((resource) => {
  modules[resource.name] = resource;
});

//import Auth from '@/store/modules/auth'
//modules.auth = Auth;

const store = new Vuex.Store({
  modules,
  state,
  mutations,
  getters,
  plugins: [createPersistedState()]
});

export default store;
